import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const MasonryGrid = ({ images, bgcolor }) => {
    const backgroundColor = bgcolor || 'none';
    return (
        <div className={`bg-[${backgroundColor}] p-8 xsm:p-16 sm:p-20`}>
            <div className={`columns-2 sm:columns-4 lg:columns-5`}>
                {images.map((image, index) => (
                    <div key={index} className="mb-4">
                        <GatsbyImage image={getImage(image.gatsbyImageData)} alt={image.alt || 'Masonry Image'} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MasonryGrid;
