import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from './logo';
import {Link} from "gatsby";
import LoadGoogleAdsLink from './load-google-ads-link';

export default function Footer() {
  return (
    <footer className="border-t border-styles">
        <div className="py-28 flex flex-col lg:flex-row items-center">
          <h3 className="text-4xl lg:text-5xl font-bold tracking-tighter leading-tight text-center lg:text-left mb-10 lg:mb-0 lg:pr-4 lg:w-1/2">
            <Link to="/" className="hover:underline flex-[0_0_20%]">
              <Logo
                  size="150px"
              />
            </Link>
            <p className="text-center text-xs text-[#e5d491]">Granja Viana - São Paulo - SP</p>
          </h3>
          <div className="flex flex-col lg:flex-row justify-center items-center lg:pl-4 lg:w-1/2">
            <LoadGoogleAdsLink
                className="
                whatsapp
                flex
                items-center
                rounded-2xl
                mx-3 bg-green-700
                text-white border
                border-black
                font-bold py-3 px-12
                lg:px-8
                duration-200
                transition-colors
                mb-6
                lg:mb-0"
            >
              <FontAwesomeIcon icon={faWhatsapp} size="3x" />
              <span className="ml-4">Whatsapp</span>
            </LoadGoogleAdsLink>
          </div>
        </div>
    </footer>
  )
}
