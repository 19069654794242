import React from 'react';
import { StructuredText, renderRule } from 'react-datocms';
import { isParagraph, isHeading, isBlockquote } from 'datocms-structured-text-utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Criacoes = ({ criacoesData }) => {
    const customRenderRules = [
        renderRule(isHeading, ({ node, children, key }) => {
            return React.createElement(
                `h${node.level}`,
                { key, className: 'font-bold font-serif text-center text-[20px]' },
                children
            );
        }),
        renderRule(isBlockquote, ({ children, key }) => {
            return <blockquote key={key} className="font-bold mb-[25px] text-3xl text-left">{children}</blockquote>;
        }),

        renderRule(isParagraph, ({ children, key }) => {
            return (
                <div key={key} className="flex flex-col items-center min-h-[180px]">
                    <p className="text-base text-justify py-8 ps-8 pe-6 sm:ps-6 sm:pe-8 md:ps-10 md:pe-10">
                        { children }
                    </p>

                </div>
            );
        }),
    ];

    const getClassFromColSize = (numeroDeColuna) => {
        try {
            const colSizes = JSON.parse(numeroDeColuna);
            let classes = '';

            // Mapping of numbers to Tailwind classes for each breakpoint
            const classMappings = {
                large: {
                    "1": "lg:flex-[0_0_100%]",
                    "2": "lg:flex-[0_0_49%]",
                    "3": "lg:flex-[0_0_32%]",
                },
                medium: {
                    "1": "md:flex-[0_0_100%]",
                    "2": "md:flex-[0_0_49%]",
                    "3": "md:flex-[0_0_32%]",
                },
                small: {
                    "1": "sm:flex-[0_0_100%]",
                    "2": "sm:flex-[0_0_49%]",
                    "3": "sm:flex-[0_0_32%]",
                }
            };

            for (const [breakpoint, size] of Object.entries(colSizes)) {
                const breakpointClass = classMappings[breakpoint][size];
                if (breakpointClass) {
                    classes += ` ${breakpointClass}`;
                }
            }

            return classes.trim();
        } catch (e) {
            console.error("Error parsing numeroDeColuna:", e);
            return '';
        }
    };

    return (
        <div className="flex flex-wrap px-8 xsm:px-16 sm:px-20 pb-[60px] gap-[1%] bg-[#14532d]">
            <div className="p-5 mb-10 flex-[1_1_100%]">
                <div className="mb-4">
                    <h2 className="font-bold font-serif text-center text-4xl my-6 text-[#e5d491]">
                        Nossas criações
                    </h2>
                </div>
            </div>
            {criacoesData.map((criacao, index) => {
                const classForThisCriacao = getClassFromColSize(criacao.numeroDeColuna);
                const pageSlug = criacao.pageLink ? criacao.pageLink.slug : '#';  // Fallback to '#' if no slug exists

                return (
                    <div
                        key={index}
                        className={`mb-4 ${classForThisCriacao}`}
                    >
                        <Link to={`/${pageSlug}`} key={index} className="block">
                        <div className="p-4 border-gradient rounded-2xl h-full">

                            <div className="flex justify-center">
                                {criacao.image && criacao.image.gatsbyImageData && (
                                    <GatsbyImage
                                        image={getImage(criacao.image.gatsbyImageData)}
                                        alt={criacao.image.alt || 'Criacao Image'}
                                        className="flex justify-center items-center flex-col mb-4"
                                    />
                                )}
                            </div>
                            <StructuredText data={criacao.paragraphs} customNodeRules={customRenderRules} />

                        </div>
                        </Link>
                    </div>

                );
            })}
        </div>
    );
};

export default Criacoes;
