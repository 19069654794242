import React from 'react';
import { StructuredText, renderRule } from 'react-datocms';
import { isParagraph, isHeading, isBlockquote } from 'datocms-structured-text-utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";

const HomeCard = ({ cardData }) => {
    const customRenderRules = [
        renderRule(isHeading, ({ node, children, key }) => {
            return React.createElement(
                `h${node.level}`,
                { key, className: 'font-bold font-serif text-center text-4xl my-6' },
                children
            );
        }),
        renderRule(isBlockquote, ({ children, key }) => {
            return (
                <blockquote
                    key={key}
                    className="font-bold mb-[25px] text-3xl text-left">
                    { children }
                </blockquote>

            );
        }),
        renderRule(isParagraph, ({ children, key }) => {
            return (
                <div key={key} className="flex flex-col justify-center">
                    <p className="text-base text-justify py-8 ps-8 pe-8 sm:ps-12 sm:pe-12 md:ps-20 md:pe-20">
                        { children }
                    </p>

                </div>
            );
        }),
    ];

    return (
        <div className="w-full px-5 py-10 bg-[#e5d491]">
            {cardData.blocks.map((block, index) => (
                <div key={index} className="mb-4">
                    {block.image && block.image.gatsbyImageData && (
                        <GatsbyImage
                            image={getImage(block.image.gatsbyImageData)}
                            alt={block.image.alt || 'Home Card Image'}
                            className="mb-4"
                        />
                    )}

                    <StructuredText
                        data={block.paragraphs}
                        customNodeRules={customRenderRules}
                    />
                    {block.iconFont && block.pageLink && (
                        <div
                            className="flex justify-center"
                        >
                            <Link
                                to={`${block.pageLink.slug}`}
                                className="inline-flex items-center text-green-900 rounded-2xl justify-center mt-4 p-5 border-2 border-green-900 m-auto"
                            >
                                <FontAwesomeIcon
                                    className="mr-[15px]"
                                    icon={JSON.parse(block.iconFont)}
                                />
                                Saiba mais
                            </Link>
                        </div>
                    )}

                </div>
            ))}
        </div>
    );
};

export default HomeCard;
