import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Header from "../components/header";
import MoreStories from "../components/more-stories";
import HomeCard from "../components/home-card";
import Criacoes from "../components/dog-breeds";
import { Helmet } from 'react-helmet';
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Footer from "../components/footer";
import MasonryGrid from "../components/masonry";
import CanilInfoComponent from "../components/canil-info";
import SectionSeparator from "../components/section-separator";

export default function Index({ data: { allPosts, site, blog } }) {
  const heroPost = allPosts.nodes[0];
  const morePosts = allPosts.nodes;
  const sliderImage = blog.slider.length > 0 ? getImage(blog.slider[0].gatsbyImageData) : null;
  const intro = blog.intro.length > 0 ? blog.intro : null;
  const slogan = blog.slogan.length > 0 ? blog.slogan : null;
  const homeCardData = blog.homeCard;
  const criacoesCardData = blog.criacoes;
  const masonryImages = blog.masonryHome.blocks.flatMap(block => block.masonryImages);
  const isBrowser = typeof window !== "undefined";


    return (
    <div className="bg-[#021002]">
        <Helmet>

            {blog.seo && blog.seo.seoMetaTags && blog.seo.seoMetaTags.tags.map((tag, index) => {
               if (tag.tagName === "meta") {
                   return <meta key={index} {...tag.attributes} />;
               } else if (tag.tagName === "title") {
                    return <title key={index}>{tag.content}</title>;
               }
                   return null;
               })}
            {isBrowser && (
                <script>
                    {`
              (function(w,d,s,l,i){
                w[l] = w[l] || [];
                w[l].push({
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), 
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', 'GTM-5PR8FGM5');
            `}
                </script>
            )}
            <noscript>
                {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5PR8FGM5"
                            height="0" width="0" style="display:none;visibility:hidden">
                     </iframe>
                `}
            </noscript>
        </Helmet>
        <HelmetDatoCms seo={blog.seo} favicon={site.favicon}/>
        <header className="flex flex-col">
            <div className="flex text-[#e5d491] border-styles">
                <Header/>
            </div>
        </header>
        {
            sliderImage &&
            <div className="relative">
                <GatsbyImage
                    image={sliderImage}
                    alt="Slider Image"
                />
                <div className="intro-wrapper">
                   <div className="hero-wrapper m-auto bg-green-950 text-[#e5d491] py-5">
                       <h1 className="text-center text-[26px] xsm:text-[36px] sm:text-[43px] md:text-[60px] font-serif text-shadow-md">
                           { intro }
                       </h1>
                       <p className="text-center text-[14px] xsm:text-[18px] sm:text-[22px] max-w-[70%] m-auto text-shadow-md">
                           { slogan }
                       </p>
                   </div>
               </div>
           </div>
        }

          <HomeCard cardData={homeCardData} />
          <SectionSeparator />
          <Criacoes criacoesData={criacoesCardData} />
          <SectionSeparator />

        <CanilInfoComponent />
        <div className="bg-[#e5d491] pt-8 px-8 xsm:pt-16 xsm:px-16 sm:pt-20 sm:px-20">
            <h3 className="text-2xl md:text-3xl mb-2">Nossa História tem muito amor</h3>
            <p>
                A partir de uma genética selecionada com critério, metodologia rigorosa e muito carinho e dedicação,
                estamos construindo a história do Canil Dois Pinheiros, assumindo uma posição de destaque dentro e fora do Brasil.
                Mais ainda, estamos construindo histórias fantásticas nas vidas de nossos clientes.
            </p>
        </div>
        <MasonryGrid images={masonryImages} bgcolor={"#e5d491"}/>
        <Footer></Footer>
    </div>

  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      slider {
        gatsbyImageData(
            layout: FULL_WIDTH, 
            width: 1200
            )
      }
      masonryHome {
          blocks {
            masonryImages {
              alt
              gatsbyImageData
            }
         }
      }
      intro
      slogan
      criacoes {
          boxShadow
          cardSide
          iconFont
          numeroDeColuna
      paragraphs {
        blocks
        value
      }
      pageLink {
        slug
      }
      image {
        gatsbyImageData(
            layout: CONSTRAINED, 
            width: 200
            )
      }
    }
    homeCard {
      blocks {
        cardSide
        paragraphs {
          blocks
          value
          links
        }
        boxShadow
        iconFont
        pageLink {
          slug
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
        }
      }
    }
      seo: seoMetaTags {
        tags
        ...GatsbyDatoCmsSeoMetaTags
      } 
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 760)
        }
        author {
          name
          picture {
            gatsbyImageData(
              layout: FIXED
              width: 48
              height: 48
              imgixParams: { sat: -100 }
            )
          }
        }
      }
    }
  }
`;
