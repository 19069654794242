import React from 'react';

const CanilInfo = () => {
    return (
        <div className="flex flex-col items-center justify-center py-10 bg-[#052e16]">
            <div className="mb-4">
                <p className="mb-2 mt-10 text-center text-2xl md:text-3xl text-[#e5d491]">
                    O Canil Dois Pinheiros possui
                    <strong> qualidades </strong>
                    que fazem a diferença
                </p>
                <p className="lead text-center text-[#e5d491]">
                    Confie em quem cria cães de raça há mais de 50 anos.
                </p>
            </div>
            <div className="px-4 py-4 w-full">
                <div className="flex justify-center flex-wrap text-[#e5d491]">
                    <div
                        className="flex flex-col flex-[0_0_100%] sm:flex-1 justify-center px-4 py-4">
                        <div>
                            <p className="text-3xl font-semibold text-center">
                                3000 +
                            </p>
                            <p className="text-lg text-center">
                                Clientes
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col flex-[0_0_100%] sm:flex-1 justify-center px-4 py-4 mt-4 sm:mt-0">
                        <div>
                            <p className="text-3xl font-semibold text-center">
                                60 anos
                            </p>
                            <p className="text-lg text-center">
                                de experiência
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col flex-[0_0_100%] sm:flex-1 justify-center px-4 py-4 mt-4 sm:mt-0">
                        <div>
                            <p className="text-3xl font-semibold text-center">
                                Inúmeros
                            </p>
                            <p className="text-lg text-center">
                                Prêmios conquistados
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CanilInfo;
